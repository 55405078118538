import { getItem } from '../../utils/localStorage';

export const accessTokenLocalStorageKey = 'Auth.AccessToken';
export const idTokenLocalStorageKey = 'Auth.IdToken';
export const userLocalStorageKey = 'Auth.User';
export const previousPageLocalStorageKey = 'PreviousPageLocation';
export const WorklocationStorageKey = 'SelectedWorkLocations'

/**
 * Retrieve the current user from local storage.
 */
export function getMeFromCache() {
    const storedKey = getItem(userLocalStorageKey);
    if (storedKey === 'undefined' || !storedKey) {
        return null;
    }
    return JSON.parse(storedKey);
}

/**
 * Retrieve the current user access token from local storage.
 */
export function getAccessToken() {
    const accessToken = getItem(accessTokenLocalStorageKey);
    if (!accessToken) {
        return '';
    }
    return accessToken;
}