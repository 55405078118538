import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";

import store from "./store";
import "./i18n";
import { getItem } from "./utils/localStorage";
import {
  accessTokenLocalStorageKey,
  getAccessTokenFromUrl,
  getLoggedInUserDetails,
  redirectToLogin,
} from "./components/Authorization";
import "@trimbleinc/modus-react-bootstrap/css/dist/modus-react-bootstrap.min.css";

const ProgressIndicator = lazy(
  () => import("./components/Common/UI/ProgressIndicator")
);

const isLoggedIn =
  getItem(accessTokenLocalStorageKey) || getAccessTokenFromUrl();

getLoggedInUserDetails().then(() => {
  const isAuthorized = isLoggedIn;
  if (isAuthorized) {
    /*
     * User token is available in local storage
     * Or is available in the query params
     */
    const root = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement
    );

    root.render(
      <React.StrictMode>
        <Suspense fallback={<ProgressIndicator />}>
          <Provider store={store}>
            <App />
          </Provider>
        </Suspense>
      </React.StrictMode>
    );
  } else {
    redirectToLogin();
  }
});
// If you want to start measuring performance in your app, pass a function
// to log results
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
