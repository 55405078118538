import React, { lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './App.scss';
import { getAccessToken, getLoggedInUserDetails, setLocationBeforeRedirect } from './components/Authorization';
import { setToken } from './services/http';
const AppNavBar = lazy(() => import('./components/Common/Layout/AppNavBar'));
const AppRoutes = lazy(() => import('./components/Common/Content/AppRoutes'))

const App = () => {
  const { t } = useTranslation();
  setToken(getAccessToken());
  setLocationBeforeRedirect();
  const [userDetails, setUserDetails] = useState(null);
  const [error, setError] = useState('');
  const [menuCollapse, setMenuCollapse] = useState(true)


  useEffect(() => {
    getLoggedInUserDetails(false).then((userDetails: any) => {
      if (userDetails.error) {
        setError('Unable to get user');
      } else {
        setUserDetails(userDetails);
      }
    })


  }, [])

  const toggleMenuCollapse = (value = !menuCollapse) => {
    setMenuCollapse(value);
  }

  const onMouseMove = (e: any) => {
    const root = document.documentElement;
    root.style.setProperty('--mouse-x', (e.clientX + 20) + 'px');
    root.style.setProperty('--mouse-y', (e.clientY + 20) + 'px');
  }
  return (
    <div className="modus-layout app-container" onMouseMove={onMouseMove}>
      <AppNavBar toggleMenuCollapse={toggleMenuCollapse} user={userDetails}></AppNavBar>
      <div
        className={`modus-body sidebar-${menuCollapse ? 'closed' : 'open'}`}
        data-modus-item="body"
        id="modusBody"
      >
        <AppRoutes toggleMenuCollapse={toggleMenuCollapse} user={userDetails} />
      </div>
    </div>
  );;

}

export default App;
