export enum MenuType {
    DASHBOARD = "dashboard",
    SECTOR = "sectors",
    DIVISION = "divisions",
    PEOPLE = "members",
    RESOURCE = "resources",
    REPORT = "reports",
    UTILITY = "utility",
    MYRESOURCE = "myresources"
}

export enum Roles {
    SUPER_ADMIN = "SUPER_ADMIN",
    ADMIN = "ADMIN",
    SECTOR_ADMIN = "SECTOR_ADMIN",
    DIVISION_ADMIN = "DIVISION_ADMIN",
    PROJECT_ADMIN = "PROJECT_ADMIN"
}

export enum PHASE {
    All= "All",
    ARCHITECT= "Architect",
    DESIGN= "Design",
    DEVELOPMENT= "Development",
    MAINTENANCE= "Maintenance",
    TESTING= "Testing"
}

export enum STATUS{
    ACTIVE="ACTIVE",
    INACTIVE="INACTIVE"
}