import Environment from "./../config/environment"
import moment from "moment";
export const apiURL = Environment.apiURL;

const serviceUrls = {
    authLogoutUrl: `${apiURL}auth/logout`,
    getMeUrl: `${apiURL}employee/me`,
    getAllDivisionsUrl: () => `${apiURL}org/division/`,
    getDivisionsBySectorIdUrl: (id: string, summary: boolean, allData: boolean, populate: boolean) => `${apiURL}org/sector/${id}?summary=${summary}&allData=${allData}&populate=${populate}`,
    getDivisionByIdUrl: (id: string, summary: boolean, populate: boolean) => `${apiURL}org/division/${id}?summary=${summary}&populate=${populate}`,
    getProjectByIdUrl: (id: string, summary: boolean, populate: boolean) => `${apiURL}project/${id}?summary=${summary}&populate=${populate}&month=${moment(Date.now())}`,
    updateProjectAllocationByUrl: (id: string) => `${apiURL}project/${id}/allocation`,
    addProjectAllocationByUrl: (id: string, selectedMonth: any) => `${apiURL}project/${id}/allocation?editionDate=${selectedMonth}`,
    deleteMemberByUrl: (id: string) => `${apiURL}employee/${id}`,
    deleteMemberFromProjectByIdUrl: (projectId: string, employeeId: string,selectedMonth: any) => `${apiURL}project/${projectId}/allocation?employeeId=${employeeId}&deletionDate=${selectedMonth}`,
    getAllMembersBySectorIdUrl: (id: string) => `${apiURL}employee?sectorId=${id}`,
    getAllMembersByDivisionIdUrl: (id: string) => `${apiURL}employee?divisionId=${id}`,
    getMemberByIdUrl: (id: string, summary: boolean, populate: boolean) => `${apiURL}employee/${id}?summary=${summary}&populate=${populate}`,
    postFileByUrl: `${apiURL}org/upload`,
    getJobByIdUrl: (id: string) => `${apiURL}org/job/${id}`,
    activateProjectByUrl:(id: any)=> `${apiURL}project/${id}/activate`,
    activateDivisionByUrl:(id: any)=> `${apiURL}org/${id}/activate`,
    activateSectorByUrl:(id: any)=> `${apiURL}org/sector/${id}/activate`,
    getResourceByUrl: (summary: boolean, populate: boolean) => `${apiURL}resource?summary=${summary}&populate=${populate}`,
    getAllSectorUrl: (summary: boolean, populate: boolean) => `${apiURL}org/sector?summary=${summary}&populate=${populate}`,
    getDivisionsByMultipleSectorsIdUrl: (ids: string[]) => `${apiURL}org/sector/division?sectorIds=${ids}`,
    getDivisionsByMultipleIdsUrl: (ids: string[], summary: boolean, populate: boolean) => `${apiURL}org/divisions?divisionIds=${ids}&summary=${summary}&populate=${populate}`,
    getAllMembersUrl: () => `${apiURL}employee?populate=true&summary=false`,
    getMyTeamDetailsUrl: (summary: boolean, populate: boolean) => `${apiURL}employee/team?summary=${summary}&populate=${populate}`,
    getReportByUrl: (
        startDate: any,
        endDate: any,
        sectorId?: any,
        divisionId?: any,
        projectId?: any,
        phase?:string[]
    ) => {
        if (projectId[0] != null && projectId.length > 0)
            return `${apiURL}report/financeReport?from=${startDate}&to=${endDate}&projectIds=${projectId}&phase=${phase}`;
        else if (divisionId[0] != null && divisionId.length > 0)
            return `${apiURL}report/financeReport?from=${startDate}&to=${endDate}&divisionIds=${divisionId}&phase=${phase}`;
        else if (sectorId[0] != null && sectorId.length > 0)
            return `${apiURL}report/financeReport?from=${startDate}&to=${endDate}&sectorIds=${sectorId}&phase=${phase}`;
        else
            return `${apiURL}report/financeReport?from=${startDate}&to=${endDate}&phase=${phase}`;
    },
    getAllocationReportByUrl: (
        startDate: any,
        endDate: any,
        sectorId?: any,
        divisionId?: any,
        projectId?: any,
        phase?: string[]
    ) => {
        if (projectId[0] != null && projectId.length > 0)
            return `${apiURL}report/allocationReport?from=${startDate}&to=${endDate}&projectIds=${projectId}&phase=${phase}`;
        else if (divisionId[0] != null && divisionId.length > 0)
            return `${apiURL}report/allocationReport?from=${startDate}&to=${endDate}&divisionIds=${divisionId}&phase=${phase}`;
        else if (sectorId[0] != null && sectorId.length > 0)
            return `${apiURL}report/allocationReport?from=${startDate}&to=${endDate}&sectorIds=${sectorId}&phase=${phase}`;
        else
            return `${apiURL}report/allocationReport?from=${startDate}&to=${endDate}&phase=${phase}`;
    },
    getDashboardByUrl: (
        startDate: any,
        endDate: any,
        sectorId?: any,
        divisionId?: any,
        projectId?: any
    ) => {
        if (projectId[0] != null && projectId.length > 0)
            return `${apiURL}org/dashboard?from=${startDate}&to=${endDate}&projectIds=${projectId}`;
        else if (divisionId[0] != null && divisionId.length > 0)
            return `${apiURL}org/dashboard?from=${startDate}&to=${endDate}&divisionIds=${divisionId}`;
        else if (sectorId[0] != null && sectorId.length > 0)
            return `${apiURL}org/dashboard?from=${startDate}&to=${endDate}&sectorIds=${sectorId}`;
        else
            return `${apiURL}org/dashboard?from=${startDate}&to=${endDate}`;
    },
    createRequestUrl: () => `${apiURL}org/request`,
    getProjectStatusByUrl: () => `${apiURL}project/status`,
    getProjectTypesByUrl:()=> `${apiURL}project/type`,
    addProjectByUrl: () => `${apiURL}project`,
    editProjectByUrl: (projectId: string) => `${apiURL}project/${projectId}?closedDate=${moment(Date.now())}`,
    deleteProjectByUrl: (projectId: string) => `${apiURL}project/${projectId}?deletionDate=${moment(Date.now())}`,
    addDivisionByUrl: () => `${apiURL}org/division`,
    editDivisionByUrl: (divisionId: string) => `${apiURL}org/division/${divisionId}`,
    deleteDivisionByUrl: (divisionId: string) => `${apiURL}org/division/${divisionId}`,
    addSectorByUrl: () => `${apiURL}org/sector`,
    editSectorByUrl: (sectorId: string) => `${apiURL}org/sector/${sectorId}`,
    deleteSectorByUrl: (sectorId: string) => `${apiURL}org/sector/${sectorId}`,
    addEmployeeUrl: () => `${apiURL}employee`,
    editEmployeeInfoByUrl: (employeeId: string, infoType: string) => `${apiURL}employee/${employeeId}?infoType=${infoType}`,
    getDesignations: () => `${apiURL}employee/designation`,
    getEmployeeTypes: () => `${apiURL}employee/type`,
    searchUrl: (searchQuery: string) => `${apiURL}org/search?query=${searchQuery}&category=`,
    getWorkLocationsUrl:()=> `${apiURL}employee/workLocation`,
    searchEmployeeReportingUrl: (searchQuery: string) => `${apiURL}org/searchReporting?query=${searchQuery}&category=`,
    searchEmployeeUrl: (searchQuery: string) => `${apiURL}org/employeeSearch?query=${searchQuery}&category=`,
}

export default serviceUrls;