import Environment from './../config/environment';
import { removeItem, setItem, getItem } from '../utils/localStorage';
import { WorklocationStorageKey, accessTokenLocalStorageKey, getAccessTokenFromUrl, logout, userLocalStorageKey } from '../components/Authorization';
import { appSettings } from '../config/settings';
let accessToken: string = '';

export const apiURL = Environment.apiURL;

export const setToken = (token: string) => {
    if (token) {
        accessToken = token;
    }
};

export const fetchBlob = async (url: string, options: any, auth: boolean = true) => {
    return await fetchReq(url, options, 'blob',false, auth);
};

export const fetchText = async (url: string, options: any, auth: boolean = true,statusrequired : boolean = false) => {
    return await fetchReq(url, options, 'text', statusrequired ,auth);
};

export const fetchReq = async (url: string, options: any, responseType?: string, statusrequired: boolean = false, auth: boolean = true) => {
    const updatedUrl = await buildUrl(url,options);
    try {
        const response: any = auth ? await httpReq(updatedUrl, options) : await fetch(updatedUrl, options);
        return constructResponse(response,responseType,statusrequired)
    } catch (error:any) {
        return { error };
    }
};


export const httpReq = async (url: string, options: any) => {
    accessToken = accessToken ? accessToken : getItem(accessTokenLocalStorageKey) || getAccessTokenFromUrl();
    if (accessToken) {
        const headers = options.headers || {};
        options.headers = {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            ...headers
        };
    }
    return fetch(url, options);
};

export const xmlHttpReq = async (url: string, options: any, setProgress: any = () => { }, requestRefObj: any = {}, tagId: any = () => '') => {
    const sendXhr = () => {
        return new Promise((resolve: any, reject: any) => {
            const xhr = new XMLHttpRequest();
            xhr.upload.addEventListener('progress', event => {
                let percentage = (event.loaded / event.total) * 100;
                setProgress(percentage);
            });

            xhr.addEventListener('load', () => {
                if (xhr.status < 400) {
                    setProgress(100);
                    resolve(xhr.response);
                } else {
                    setProgress(0);
                    reject(xhr.statusText || 'Error');
                }
            });
            xhr.addEventListener('error', () => {
                setProgress(0);
                reject('Error');
            });

            requestRefObj.abort = () => xhr.abort();

            xhr.open(options.method, url);
            const headers = options.headers;
            for (let key in headers) {
                xhr.setRequestHeader(key, headers[key]);
            }
            xhr.send(options.body);

            xhr.onreadystatechange = function () {
                if (this.readyState === this.HEADERS_RECEIVED) {
                    tagId(xhr.getResponseHeader("etag"));
                }
            }

        });
    };
    try {
        const xhrRes = await sendXhr();
        return xhrRes;
    } catch (error) {
        return { error };
    }
};


export const buildUrl =  async(url: string, options: any) => {
    let workLocations = getItem(WorklocationStorageKey);
    if (workLocations === "") {
        const userInfo: any = JSON.parse(getItem(userLocalStorageKey) ?? "");
        workLocations = userInfo.workLocation;
    }
    if (options.method === "GET" && workLocations) { 
        if (url.includes("?") && options.location) {
            url += `&location=${options.location}`;
        }
        else if (url.includes("?")) {
            url += `&location=${workLocations}`;
        } else {
            url += `?location=${workLocations}`;
        }
    }
    return url;
}


export const constructResponseBody =  async (response: any,responseType: any) =>
{
    const responseBody = responseType === 'blob'
            ? await response.blob()
            : responseType === 'text'
                ? await response.text()
                : await response.json();

    return responseBody;
}

export const constructResponse = (response: any,responseType:any,statusrequired: boolean) => {
    if (response.ok) {
        const responseBody = constructResponseBody(response,responseType);
        removeItem('retryCount');
        return statusrequired ? {
            data:responseBody,
            statuscode:response.status} : responseBody;
    }
    if (response.status === 401) {
        logout();
        return;
    }
    else {
    return statusrequired ? { error: (response.data && response.data.message ) || response.statusText || 'Error',statuscode:response.status } : { error: (response.data && response.data.message ) || response.statusText || 'Error' };
    }
}