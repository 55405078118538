import { getItem, removeItem, setItem } from "../../utils/localStorage";
import { getHashParameters, parseArguments } from "../../utils/location";
import { WorklocationStorageKey, accessTokenLocalStorageKey, getMeFromCache, idTokenLocalStorageKey, previousPageLocalStorageKey, userLocalStorageKey } from "./session";
import Environment from '../../config/environment';
import { fetchReq, httpReq } from "../../services/http";
import serviceURLs from "../../services/serviceUrls";
import { Roles } from "../../enum/enum";
import { appSettings } from "../../config/settings";

const {
    getMeUrl,
    authLogoutUrl
} = serviceURLs;



export function redirectToLogin() {
    // Remove previously stored values if any
    removeItem(accessTokenLocalStorageKey);
    removeItem(idTokenLocalStorageKey);

    // Store the url to redirect back where the user started
    let storeWindowLocation = window.location.href;
    const url = new URL(storeWindowLocation);
    if (url.searchParams.get('sessionDataKey')) {
        //Remove 'sessionDataKey' from the query param if it set by the logout URL
        url.searchParams.delete('sessionDataKey');
        storeWindowLocation = url.href;
    }
    setItem(previousPageLocalStorageKey, storeWindowLocation);

    // Initiating TID flow
    window.location.assign(Environment.loginURL);
}

export function setLocationBeforeRedirect() {
    const prevLocation = getItem(previousPageLocalStorageKey);
    if (prevLocation) {
        removeItem(previousPageLocalStorageKey);
        window.location.assign(prevLocation);
    }
}

/**
 * Retrieve the arguments form the URL, and return it.
 */
export function getAccessTokenFromUrl() {
    const hashQueryParams = parseArguments(getHashParameters());
    const accessToken = hashQueryParams.access_token;
    const idToken = hashQueryParams.id_token;
    if (accessToken) {
        setItem(accessTokenLocalStorageKey, accessToken);
    }
    if (idToken) {
        setItem(idTokenLocalStorageKey, idToken);
    }
    return accessToken;
}

export async function logout() {
    removeItem(accessTokenLocalStorageKey);
    removeItem(userLocalStorageKey);
    removeItem(WorklocationStorageKey);
    window.location.assign(Environment.loginURL);
};

export async function getLoggedInUserDetails(isCached = true) {
    let accessToken = getItem(accessTokenLocalStorageKey);
    if (accessToken) {
        let userDetails = isCached && getMeFromCache();
        if (userDetails) {
            return userDetails;
        }
        userDetails = await fetchReq(getMeUrl, {
            method: 'GET',
        });
        const workLocations = getItem(WorklocationStorageKey);
        if (!workLocations) {
            setItem(WorklocationStorageKey,  appSettings.location);
        }
        if (userDetails && !userDetails.error) {
            setItem(userLocalStorageKey, JSON.stringify(userDetails));
        }
        return userDetails;
    }
}

export function getLoggedInUserRoles() {
    let userDetails = getMeFromCache();
    if (userDetails && userDetails.roleNames && userDetails.roleNames.length > 0)
        switch (true) {
            case (userDetails.roleNames.includes(Roles.SUPER_ADMIN)):
                return Roles.SUPER_ADMIN
            case (userDetails.roleNames.includes(Roles.ADMIN)):
                return Roles.ADMIN
            case (userDetails.roleNames.includes(Roles.SECTOR_ADMIN)):
                return Roles.SECTOR_ADMIN
            case (userDetails.roleNames.includes(Roles.DIVISION_ADMIN)):
                return Roles.DIVISION_ADMIN
            case (userDetails.roleNames.includes(Roles.PROJECT_ADMIN)):
                return Roles.PROJECT_ADMIN
            default:
                return null
        }
    else
        return null;
}
// todo: get roles of the user, retun the role with the highest priority.

export * from "./session"